import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import arrow from "root/assets/arrow.svg";

import styles from "./index.module.css";

const queryPositions = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/positions/" } }
      sort: { order: DESC, fields: frontmatter___position }
    ) {
      edges {
        node {
          frontmatter {
            path
            position
            location
            typology
          }
        }
      }
    }
  }
`;

const CareersPositions = () => {
  const positions = useStaticQuery(queryPositions);

  return (
    <Section color="green">
      <div className={styles.root}>
        <div className={styles.title}>
          <Typography variant="h1" color="white" weight="bold">
            Open Positions
          </Typography>
        </div>
        {positions.allMdx.edges.map(({ node }) => (
          <Link
            to={`/careers/${node.frontmatter.path}`}
            className={styles.link}
            key={node.frontmatter.path}
          >
            <div className={styles.position}>
              <div className={styles.positionTitle}>
                <Typography color="white" weight="bold">
                  {node.frontmatter.position}
                </Typography>
              </div>
              <div className={styles.positionLocation}>
                <Typography color="white">
                  {node.frontmatter.location}
                </Typography>
              </div>
              <div className={styles.positionLocation}>
                <Typography color="white">
                  {node.frontmatter.typology}
                </Typography>
              </div>
              <img src={arrow} alt="" className={styles.arrow} />
            </div>
          </Link>
        ))}
        <a href="mailto:jobs@drivit.com" className={styles.link}>
          <div className={styles.spontaneous}>
            <Typography color="white" weight="bold">
              Spontaneous Application
            </Typography>
            <img src={arrow} alt="" className={styles.arrow} />
          </div>
        </a>
      </div>
    </Section>
  );
};

export default CareersPositions;
