import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import CareersHero from "root/sections/CareersHero";
import CareersPerks from "root/sections/CareersPerks";
import CareersPositions from "root/sections/CareersPositions";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit | Careers",
  description:
    "We’re hiring! Drivit is a tech company that is making driving safer and more sustainable. Come make a difference in the way people travel.",
  keywords:
    "Tech company in Lisbon, Jobs in Lisbon in startups, Tech startups in Lisbon, Tech jobs in Lisbon Portugal, Sustainability jobs, Environmentally friendly jobs, Mobility jobs, Telematics jobs, Driving safety jobs, Social entrepreneurship, Social impact jobs, Digital-signal processing jobs",
};

const CareersPage = () => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header currentPage="careers" background="white" />
        <CareersHero />
        <CareersPerks />
        <CareersPositions />
        <Footer />
      </Layout>
    </div>
  );
};

export default CareersPage;
