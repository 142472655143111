import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import backgroundShape1 from "root/assets/careers-hero-shape1.svg";
import backgroundShape2 from "root/assets/careers-hero-shape2.svg";
import backgroundShape3 from "root/assets/careers-hero-shape3.svg";
import backgroundShape4 from "root/assets/careers-hero-shape4.svg";

import styles from "./index.module.css";

const CareersHero = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg1: file(relativePath: { eq: "careers-hero1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImg2: file(relativePath: { eq: "careers-hero2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImg3: file(relativePath: { eq: "careers-hero3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 678, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.root}>
        <Section color="transparent">
          <div className={styles.title}>
            <Typography variant="h1" color="green" weight="bold">
              Join us in this journey that is revolutionizing mobility
            </Typography>
          </div>
        </Section>
        <Section color="transparent">
          <div className={styles.backgroundSection}>
            <img
              className={styles.backgroundShape1}
              alt=""
              src={backgroundShape1}
            />
            <img
              className={styles.backgroundShape2}
              alt=""
              src={backgroundShape2}
            />
          </div>
          <div className={styles.sectionContent}>
            <div className={styles.leftSide}>
              <div className={styles.textCategory}>
                <Typography color="green" weight="bold">
                  Culture
                </Typography>
              </div>
              <div className={styles.textTitle}>
                <Typography variant="h2" weight="bold">
                  Helping to transform mobility
                </Typography>
              </div>
              <div className={styles.firstText}>
                <Typography>
                  We work hard to help transform the way the world moves around
                  and to inspire others to do the same. We’re a group of experts
                  in mobility, engineers, developers and data scientists, who
                  try to help each other to succeed in solving though problems
                  and in building amazing technology.
                </Typography>
              </div>
            </div>
            <div className={styles.rightSide}>
              <Img
                className={styles.imgLeft}
                alt="team working"
                fluid={data.heroImg1.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </Section>

        <Section color="transparent">
          <div className={styles.backgroundSection}>
            <img
              className={styles.backgroundShape3}
              alt=""
              src={backgroundShape3}
            />
            <img
              className={styles.backgroundShape4}
              alt=""
              src={backgroundShape4}
            />
          </div>
          <div className={styles.sectionContent2}>
            <div className={styles.rightSide}>
              <Img
                className={styles.imgRight}
                alt="team working"
                fluid={data.heroImg2.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
            <div className={styles.leftSide2}>
              <div className={styles.textCategory}>
                <Typography color="green" weight="bold">
                  People
                </Typography>
              </div>
              <div className={styles.textTitle}>
                <Typography variant="h2" weight="bold">
                  Driven at pushing innovation
                </Typography>
              </div>
              <div className={styles.firstText}>
                <Typography>
                  We are constantly looking for people who are deeply passionate
                  about what we do, who are incredible talented and yet humble,
                  who brush aside politics or ego-centric decisions and seek
                  what’s best for the team and who, under no circumstances, say
                  “that’s not my problem”.
                </Typography>
              </div>
            </div>
          </div>
        </Section>

        <div className={styles.imgSection}>
          <div className={styles.imgSectionContent}>
            <div className={styles.leftSide3}>
              <div className={styles.textCategory3}>
                <Typography color="green" weight="bold">
                  Tech
                </Typography>
              </div>
              <div className={styles.textTitle}>
                <Typography variant="h2" weight="bold">
                  State-of-the-art driving analytics
                </Typography>
              </div>
              <div className={styles.firstText}>
                <Typography>
                  At Drivit we use cutting-edge technology and data to make
                  roads safer and help drivers be more efficient. Our tools for
                  the job range from low-level programming languages for digital
                  signal processing, to cutting-edge machine learning frameworks
                  for fast knowledge discovery.
                </Typography>
              </div>
            </div>
            <div className={styles.rightSide3}>
              <Img
                className={styles.imgLeft3}
                alt="team working"
                fluid={data.heroImg3.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersHero;
