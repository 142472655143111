import React from "react";

import Typography from "root/components/Typography";
import Section from "root/components/Section";

import styles from "./index.module.css";

const perksList = [
  {
    perk: "Transform with us",
    description: "Dare to not be just another one",
  },
  {
    perk: "Work with the founders",
    description: "We don’t do hierarchies",
  },
  {
    perk: "Communications plan",
    description: "Free cellphone and mobile data plan",
  },
  {
    perk: "Flexible schedule",
    description: "No clock in nor clock out",
  },
  {
    perk: "Stock options",
    description: "Get Drivit shares at an exclusive price",
  },
  {
    perk: "Health insurance",
    description: "Special care for your well-being",
  },
];

const CareersPerks = () => {
  return (
    <Section color="lightGrey">
      <div className={styles.title}>
        <Typography variant="h2" weight="bold">
          Perks
        </Typography>
      </div>
      <div className={styles.perksList}>
        {perksList.map(perk => (
          <div className={styles.perk} key={perk.perk}>
            <Typography weight="bold" color="green">
              {perk.perk}
            </Typography>
            <div className={styles.perkDescription}>
              <Typography>{perk.description}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default CareersPerks;
